import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Images from '../../common/Index';
import * as Api from "../../../Apis";
import moment from 'moment/moment';
import { Modal, Pagination } from 'react-bootstrap';
import { useMediaQuery } from '@uidotdev/usehooks';


function Support() {
    const searchParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()
    const location = useLocation()
    const [logs, setLogs] = useState([])
    const [show, setshow] = useState(false)
    const [page, setpage] = useState(1)
    const [active, setActive] = useState({})
    const [storeList, setstoreList] = useState([])
    const [activeStore, setactiveStore] = useState(searchParams.get("store_id") || "")

    const limit = process.env.REACT_APP_LIMIT || 20
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        store_id: searchParams.get("store_id") || ""
    })
    const [totalCount, setTotalCount] = useState(0)
    const [count, setCount] = useState(1)

    const handleClose = () => {
        setshow(false)
    }

    const getSupportLog = (param) => {
        Api.GetApiSupport(`support/logs${param}`).then(res => {
            console.log("logs res", res)
            if (res.error == true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res?.data?.data) {
                    setLogs(res.data.data?.rows)
                    let newCount = Math.ceil(parseInt(res.data.data?.count) / limit)
                    setTotalCount(res.data.data?.count || 0)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }
            }
        })
    }
    const getStoreList = () => {
        Api.GetApiSupport('support/store').then(res => {
            console.log("store list", res)
            if (res.error == true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res?.data?.data) {
                    let arr = []
                    res.data.data.map((opt) => {
                        arr.push({ value: opt?.store_id, label: opt?.store?.store_name || opt?.store_id })
                    })
                    setstoreList(arr)
                }
            }
        })
    }

    const maxVisiblePages = 5;

    const getPageItems = () => {
        const pageItems = [];
        const totalPages = count.length;
        if (totalPages) {
            console.log("totalPages: ", totalPages)
            for (let number = 1; number <= totalPages; number++) {
                if (
                    number == 1 ||
                    number == totalPages ||
                    (number >= params.page - Math.floor(maxVisiblePages / 2) &&
                        number <= params.page + Math.floor(maxVisiblePages / 2))
                ) {
                    pageItems.push(
                        <Pagination.Item
                            key={number}
                            active={number == params.page}
                            onClick={() => pageChange(number)}
                        >
                            {number}
                        </Pagination.Item>
                    );
                } else if (
                    (number == 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
                    (number == totalPages - 1 &&
                        params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
                ) {
                    // Display ellipsis for the second and second-to-last pages
                    pageItems.push(<Pagination.Ellipsis key={number} />);
                }
            }

            return pageItems;
        }
    };

    const logout = () => {
        localStorage.removeItem("support_token")
        navigate("/support/login")
    }

    const handleSelect = (e) => {
        // getSupportLog(e.target.value)
        setactiveStore(e.target.value)
        
        setparam("store_id", e.target.value)
        setParams({ ...params, store_id: e.target.value })
    }

    const viewDetail = (data) => {
        setshow(true)
        setActive(data)
    }
    function PrettyPrint(data) {
        return <pre>{JSON.stringify(data, null, 2)}</pre>
    }

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    const prevPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) - 1)
        setParams({ ...params, page: Number(params.page) - 1 })
    }
    const nextPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) + 1)
        setParams({ ...params, page: Number(params.page) + 1 })
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setParams({ ...params, page: number })
            setparam("page", number)
            // setloading(true)
        }
    }


    useEffect(() => {
        let storeId = searchParams.get("store_id") || params.store_id || storeList[0]?.value
        searchParams.set("page", params.page)
        if(storeId){
            searchParams.set("store_id", storeId)
        }
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        console.log("storeId: ", storeId)
        if (storeList.length > 0) {
            getSupportLog(location.search || "?page=1")
            setactiveStore(storeId)
        }
    }, [limit, params, storeList])

    useEffect(() => {
        getStoreList()

    }, [])
console.log("activeStore: ", activeStore)
console.log("params: ", params)
const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    return (
        <div className='loginContainer justify-content-start py-3'>
            <div className='container'>
                <div className="" >
                    <div className="d-flex justify-content-between align-items-start">
                        <div className='loginLogo'>
                        <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                        </div>
                        <button className='btn btn-text-danger border-0 p-0' onClick={logout}>Logout</button>
                    </div>
                    <div className='cardBoxStore__bodyHeading'>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <h4>My activities({totalCount})</h4>
                                <div className='form-group mb-0 ms-5'>
                                    <div className='form-select-outer'>
                                        <select name="revenue" className='form-control sm w-100 pe-5' value={activeStore} onChange={handleSelect}>
                                            {storeList.map((opt, i) => {
                                                return (
                                                    <option key={i} value={opt?.value}>{opt?.label}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <p className='mb-0 ms-4'>Store ID: <strong>{activeStore}</strong></p>
                            </div>
                            <NavLink to="/support/connect" className='btn btn-primary'>Connect</NavLink>
                        </div>
                        <div className="cardBox mt-4 p-4">
                            {logs.length > 0 ?
                                <ul className='activity_logs'>
                                    {logs.map((data, i) => {
                                        return (
                                            <li className='activity_logs_item' key={i}>
                                                <p className='mb-0'>You have {data?.method == "DELETE" ? "deleted" : data?.method == "CREATE" ? "created" : "updated"} {data?.section_name} section. <a style={{ color: "blue", cursor: "pointer" }} onClick={() => viewDetail(data)}>View detail</a></p>
                                                {/* {data?.message.includes(" to ") ?
                                                    <>
                                                        <p className='mb-0'>
                                                            {data?.message.split(" to ")[0]}
                                                        </p>
                                                        <p className='mb-0'><strong>to</strong></p>
                                                        <p className='mb-0'>
                                                            {data?.message.split(" to ")[1]}
                                                        </p>
                                                    </>
                                                    :
                                                    data?.message
                                                } */}
                                                {/* {data?.url ?
                                                    <p>
                                                        <a href={!data?.url.startsWith("http") ? `https://${data?.url}` : data?.url} target='_blank'>{!data?.url.startsWith("http") ? `https://${data?.url}` : data?.url}</a>
                                                    </p>
                                                    : null
                                                } */}
                                                <p style={{ color: "#999" }}><small>{moment(data?.createdAt).format("lll")}</small></p>
                                            </li>
                                        )
                                    })}
                                </ul>
                                :
                                <div className=''>
                                    No activity yet
                                </div>
                            }
                            <Pagination>
                                <Pagination.First onClick={prevPage} disabled={params.page == 1} />
                                {getPageItems()}
                                <Pagination.Last
                                    onClick={nextPage}
                                    disabled={params.page == count.length}
                                // disabled={params.page == count}
                                />
                            </Pagination>
                        </div>
                    </div>
                </div>

                <Modal show={show} onHide={handleClose} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>View log detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='mb-0'>{active?.method == "DELETE" ? "Deleted" : active?.method == "CREATE" ? "Created" : "Updated"} {active?.section_name} {active?.method !== "CREATE" ? "section" : ""} on: <span style={{ color: "#666" }}>{moment(active?.createdAt).format("lll")}</span>. </p>
                        {active?.url ?
                            <p><a href={active?.url} target='_blank'>{active?.url}</a></p> :
                            null
                        }
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <h5>Old data</h5>
                                <div className='view_json_block'>
                                    {PrettyPrint(active?.previouslog)}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <h5>New data</h5>
                                <div className='view_json_block'>
                                    {PrettyPrint(active?.newlog)}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

export default Support;