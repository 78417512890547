import React, { useState, useEffect } from 'react';
import Images from '../common/Index';
import { useMediaQuery } from '@uidotdev/usehooks';


function Token() {
    const [token, setToken] = useState("")
    const handleToken = () => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "submit_form" })
                .then((token) => {
                    console.log("recaptcha token", token)
                    setToken(token)
                })
        })
    }


    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        // script.addEventListener("load", handleLoaded)
        // window.onSubmit = () => ("load", handleLoaded)
        document.body.appendChild(script)
    }, [])
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');


    return (
        <div className='loginContainer'>
            <div className="cardBox cardBox__login" >
                <div className='loginLogo'>
                    <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                </div>

                <button className='btn btn-danger' onClick={handleToken}>Get token</button>
                {token ?
                    <p style={{ wordBreak: "break-all" }} className='mt-3'>{token}</p>
                    : null}

            </div>
        </div>
    );
}

export default Token;