import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Api from "../../Apis";
import Images from '../common/Index';

import PhoneInput from 'react-phone-input-2'
import { useMediaQuery } from "@uidotdev/usehooks";

function Register() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const searchParams = new URLSearchParams(typeof window !== 'undefined' && window.location.search)
    let navigate = useNavigate()
    const [value, setValue] = useState({
        first_name: "",
        last_name: "",
        email: searchParams.get("email") || "",
        password: "",
        confirm_password: ""
    })
    const [phone, setPhone] = useState()
    const [btnLoader, setBtnLoader] = useState(false)
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [strength, setstrength] = useState("")

    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        if (e.target.name == "password") {
            var reg = new RegExp(
                "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
            );
            var validPass = reg.test(e.target.value);

            if (e.target.value.length >= 8 && !validPass) {
                setstrength("Medium")
            } else if (e.target.value.length >= 8 && validPass) {
                setstrength("Strong")
            } else if (e.target.value == "") {
                setstrength("")
            } else {
                setstrength("Weak")
            }
        }
    }
    const changeVisibility = (type) => {
        if (type == "password") {
            setShow(!show)
        } else {
            setShow2(!show2)
        }
    }

    const handleNumberChange = (value, data) => {
        setPhone(`+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`)
    };

    const authData = (data) => {
        Api.setToken("token", data.token)
        let auth_data = {}
        auth_data["email"] = data?.email
        auth_data["first_name"] = data?.first_name
        auth_data["last_name"] = data?.last_name
        auth_data["phone"] = data?.phone
        localStorage["auth_data"] = JSON.stringify(auth_data)
        localStorage.removeItem("login_token")
        Api.GetApi('store?page=1&type=active').then(res => {
            console.log("res list: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                if (res.res.data?.errorCode == "TOKEN_EXPIRE") {
                    Api.removeToken()
                }
            } else {
                if (res.data.data.length == 0) {
                    Api.GetApi('store?page=1&type=expire').then(res2 => {
                        console.log("res2 list: ", res2)
                        if (res2.error === true) {
                            Api.Alert(res2.response.data.error, "error")
                            if (res2.res.data?.errorCode == "TOKEN_EXPIRE") {
                                Api.removeToken()
                            }
                        } else {
                            if (res.data.data.length == 0) {
                                navigate("/store-login")
                            }
                        }
                    })
                }
                if (data.length == 1) {
                    let resData = data[0]
                    Api.PostApi("store/storelogin", { "store_id": resData?.storeId }).then(res => {
                        console.log("res store login: ", res)
                        if (res.error === true) {
                            Api.Alert(res.response.data.error, "error")
                        } else {
                            if (data.token) {
                                let url = ""
                                let domain = ""
                                if (resData?.liveDomain) {
                                    domain = resData?.liveDomain
                                } else {
                                    domain = `https://${resData?.domain}`
                                }
                                if (Api.buildtype == "local") {
                                    url = `http://${resData.store_name.split(" ").join("-")}.localhost:3001/admin?token=${data.token}&id=${resData?.store[0]?.id}&role=${resData?.store[0]?.role}`
                                } else {
                                    url = `https://${resData?.domain}/admin?token=${data.token}&id=${resData?.store[0]?.id}&role=${resData?.store[0]?.role}`
                                }
                                if (resData?.store[0]?.role == 3 && !resData?.store[0]?.is_accepted) {
                                    // let a = document.createElement('a');
                                    // a.href = url;
                                    // if (window.confirm('Accept account invite. Click Ok')) {
                                    //     Api.PostApi('staff/accept', { store_id: resData.storeId }).then(resp => {
                                    //         console.log("res accept list: ", resp)
                                    //         if (resp.error === true) {
                                    //             Api.Alert(resp.response.data.error, "error")
                                    //             if (resp.res.data.errorCode == "TOKEN_EXPIRE") {
                                    //                 Api.removeToken()
                                    //             }
                                    //         } else {
                                    //             a.click();
                                    //         }
                                    //     })
                                    // };
                                    navigate("/store-login")
                                } else {
                                    window.open(url, '_self')
                                }
                            }
                        }
                    })
                } else {
                    navigate("/store-login")
                }
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        var reg = new RegExp(
            "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
        );
        var validPass = reg.test(value?.password);

        if (value?.first_name.trim() == "") {
            Api.Alert("First name can't be blank", "error")
        } else if (value?.last_name.trim() == "") {
            Api.Alert("Last name can't be blank", "error")
        } else if (value?.email.trim() == "") {
            Api.Alert("Email can't be blank", "error")
        } else if (value?.password.trim() == "") {
            Api.Alert("Password can't be blank", "error")
        } else if (value?.confirm_password.trim() == "") {
            Api.Alert("Please confirm password", "error")
        } else if (value?.confirm_password !== value?.password) {
            Api.Alert("Password confirmation does not match. Re-enter your password.", "error")
        } else if (value?.email.trim() !== "" && !pattern.test(value.email)) {
            Api.Alert("Please enter valid email", "error")
        } else if (value?.password !== "" && value?.password.length < 4) {
            // } else if (value?.password !== "" && !validPass) {
            Api.Alert("Pick a stronger password for your account. Try using a combination of letters, numbers, and special symbols.", "error")
        }
        else {
            setBtnLoader(true)
            let data = {
                ...value,
                phone
            }
            window.grecaptcha.ready(_ => {
                window.grecaptcha
                    .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "submit_form" })
                    .then((token) => {
                        console.log("token", token)
                        if (token) {
                            let body1 = {
                                ...data,
                                captcha: token
                            }
                            Api.PostApi("users/register", body1).then(res => {
                                console.log("res register: ", res)
                                setBtnLoader(false)
                                if (res.error === true) {
                                    Api.Alert(res.response.data.error, "error")
                                } else {
                                    // if (process.env.REACT_APP_MODE == "dev" && res.data.data?.mail_url) {
                                    //     let a = document.createElement('a');
                                    //     a.href = res.data.data.mail_url;
                                    //     if (window.confirm('Please verify your email. Click Ok')) {
                                    //         a.click();
                                    //     };
                                    // } else {
                                    Api.Alert("Account created successfully", "success")
                                    setTimeout(() => {
                                        authData(res.data.data)
                                        // navigate("/login")
                                    }, 1500);
                                    // }
                                }
                            })
                        }else{
                            setBtnLoader(false)
                        }
                    })
            })
        }
    }


    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        // script.addEventListener("load", handleLoaded)
        // window.onSubmit = () => ("load", handleLoaded)
        document.body.appendChild(script)
    }, [])


    return (
        <div className='loginContainer'>
            <div className="cardBox cardBox__login" >
                <div className='loginLogo'>
                    <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                </div>
                <form onSubmit={handleSubmit} className='login_outer'  >
                    <div className='row gx-2'>
                        <div className='col-sm-6'>
                            <div className='form-group mb-3'>
                                <div>
                                    <input type="text" name="first_name" value={value?.first_name} className={`form-control w-100 required ${value?.first_name == "" ? "" : "filled"}`} placeholder='First Name*' onChange={handleOnchange} />
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='form-group mb-3'>
                                <div>
                                    <input type="text" name="last_name" value={value?.last_name} className={`form-control w-100 required ${value?.last_name == "" ? "" : "filled"}`} placeholder='Last Name*' onChange={handleOnchange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div>
                            <input type="email" name="email" value={value?.email} className={`form-control w-100 required ${value?.email == "" ? "" : "filled"}`} placeholder='Email*' onChange={handleOnchange} />
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='customPhone'>
                            {/* <input type="number" name="phone" className='form-control w-100 mb-2' onChange={handleOnchange} /> */}
                            <PhoneInput
                                enableSearch={true}
                                disableAreaCodes={false}
                                country={'in'}
                                value={phone}
                                // onChange={phone => setPhone({ phone })}
                                inputExtrastate={{ name: 'mobile', required: true }}
                                inputProps={{ maxLength: '17' }}
                                onChange={(e, x) => handleNumberChange(e, x)}
                                isValid={(value, data) => {
                                    if (
                                        value
                                            .replace(/[^0-9]+/g, "")
                                            .slice(data && (data.dialCode.length)).length < 10 &&
                                        value
                                            .replace(/[^0-9]+/g, "")
                                            .slice(data && (data.dialCode.length)).length > 0
                                    ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }}
                                placeholder=""
                                countryCodeEditable={false}
                                defaultMask=".. ... ...."
                            />
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='flex_input'>
                            <input type={show ? "text" : "password"} name="password" value={value?.password} className={`form-control w-100 required ${value?.password == "" ? "" : "filled"}`} placeholder='Password*' onChange={handleOnchange} />

                            {value?.password !== "" &&
                                <div className='password_bar'>
                                    <span style={{
                                        width: `${value?.password.length * 10 / 2}%` || "0%",
                                        backgroundColor: strength == "Strong" ? "#54d55a " : strength == "Medium" ? "#979797" : "#ff0000"
                                    }}></span>
                                </div>
                            }

                            <span className="form-addon-icon" onClick={() => changeVisibility("password")}>
                                {!show ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                        </div>
                        <p className="mt-1" style={{ color: strength == "Strong" ? '#54d55a' : strength == "Medium" ? "#979797" : '#ff0000' }}><strong>{strength}</strong></p>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='flex_input'>
                            <input type={show2 ? "text" : "password"} name="confirm_password" value={value?.confirm_password} className={`form-control w-100 required ${value?.confirm_password == "" ? "" : "filled"}`} placeholder='Confirm Password*' onChange={handleOnchange} />
                            <span className="form-addon-icon" onClick={() => changeVisibility("confirm_password")}>
                                {!show2 ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                        </div>
                    </div>
                    {value?.password !== "" &&
                        <ol className='password_note'>
                            <li>Strong Password must contain atleast 8 Characters</li>
                            <li>Strong Password must have atleast one Capital Letter</li>
                            <li>Strong Password must have atleast one Number and Special Character</li>
                        </ol>
                    }
                    <div className='form-group mb-3'>
                        <button className={`btn btn-primary w-100 ${btnLoader ? "disabled" : ""}`}>{btnLoader ? "Please wait" : "Sign up"}</button>
                    </div>
                </form>

            </div>
            <div className='signUpLink text-center'>
                Already have an account? <NavLink to="/login"> Log in</NavLink>
            </div>
        </div>
    );
}

export default Register;