import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';

import * as Api from "../../../Apis"
import '../AdminArea.css'
import Images from '../../common/Index';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import moment from 'moment';
import { useMediaQuery } from '@uidotdev/usehooks';


export default function AdminArea() {
    const searchParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()
    const location = useLocation()
    const [activeTab, setactiveTab] = useState("pending")
    const [userData, setuserData] = useState([
        // { first_name: "Amandeep", last_name: "singh", email: "aman@gmail.com", phone: "9599989797", stores: 2, id: 1, "created_at": "2023-09-05T11:31:03.000Z", },
        // { first_name: "Shahil", last_name: "mishra", email: "shahil@gmail.com", phone: "9599989797", stores: 1, id: 2, "created_at": "2023-09-05T11:31:03.000Z", },
    ])
    const limit = process.env.REACT_APP_LIMIT || 20
    const [modalData, setmodalData] = useState({})
    const [value, setValue] = useState({})
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        search: "",
    })
    const [totalCount, setTotalCount] = useState(0)
    const [errors, setError] = useState(false)
    const [count, setCount] = useState(1)
    const [phone, setPhone] = useState()

    const [show, setshow] = useState(false)
    const handleClose = () => {
        setmodalData({})
        setshow(false)
    }
    const changeTab = (val) => {
        if (activeTab === "pending") {
            setactiveTab("completed")
        } else {
            setactiveTab("pending")
        }

    }

    const logout = () => {
        localStorage.removeItem("admin_token")
        navigate("/admin/login")
    }

    const getList = (param) => {
        Api.GetApiAdmin(`administrative/user/list${param}`).then(res => {
            console.log("user res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                setuserData(res.data.data?.rows)
                handleClose()
                let newCount = Math.ceil(parseInt(res.data.data?.count) / limit)
                setTotalCount(res.data.data?.count || 0)
                if (newCount !== 0) {
                    let items = [];
                    for (let number = 1; number <= newCount; number++) {
                        items.push(number);
                    }
                    setCount(items)
                } else {
                    setCount(1)
                }

            }
        })
    }

    console.log("res", userData)

    const updateStatus = () => {
        Api.PutApiAdmin(`domain-verification/updateStatus/${modalData.domain_verification_id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                Api.Alert(res.data.data.message, "success")
                getList(activeTab)
                changeTab()
            }
        })

    }

    const openModal = (data) => {
        setshow(true)
        let body = { ...data }
        body["phone"] = data?.phone && !data?.phone.includes("+91") ? `+91 ${data?.phone}` : data?.phone || ""
        setmodalData(body)
    }

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const maxVisiblePages = 5;

    const getPageItems = () => {
        const pageItems = [];
        const totalPages = count.length;

        for (let number = 1; number <= totalPages; number++) {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= params.page - Math.floor(maxVisiblePages / 2) &&
                    number <= params.page + Math.floor(maxVisiblePages / 2))
            ) {
                pageItems.push(
                    <Pagination.Item
                        key={number}
                        active={number === params.page}
                        onClick={() => pageChange(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            } else if (
                (number === 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
                (number === totalPages - 1 &&
                    params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
            ) {
                // Display ellipsis for the second and second-to-last pages
                pageItems.push(<Pagination.Ellipsis key={number} />);
            }
        }

        return pageItems;
    };


    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    console.log("value: ", value)

    const handleNumberChange = (val, data) => {
        setValue({ ...value, phone: `+${data.dialCode} ${val.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}` })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (value?.first_name == "" || value?.email == "") {
            setError(true)
        } else {
            setError(false)
            let body = {
                ...value
            }
            Api.PutApiAdmin(`administrative/updateUserDetails/${modalData?.id}`, body).then(res => {
                console.log("updateUserDetails res", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        Api.Alert(res.data.data, "success")
                        getList(location.search || "?page=1")
                    }
                }
            })
        }
    }

    const prevPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) - 1)
        setParams({ ...params, page: Number(params.page) - 1 })
    }
    const nextPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) + 1)
        setParams({ ...params, page: Number(params.page) + 1 })
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setParams({ ...params, page: number })
            setparam("page", number)
            // setloading(true)
        }
    }


    //Search change
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("page", 1)
            setparam("search", e.target.value)
        }
    }

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== "") {
                console.log("search:", search);
                setParams({ ...params, 'page': 1, 'search': search })
                setparam("search", search)
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);


    useEffect(() => {
        searchParams.set("page", params.page)
        searchParams.set("limit", limit)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        // if (location.search !== "") {
        getList(location.search || "?page=1&limit=20")
        // }
    }, [limit, params])
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    console.log("modaldata", modalData)

    return (
        <>

            <div className='loginContainer justify-content-start py-3 networkPage'>
                <div className="container">
                    <div className="metworkHeader ">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="loginLogo">
                            <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                            </div>
                            <div className='admin_header'>
                                <NavLink to="/admin/users" className="btn btn-text-primary border-0 p-0 me-4">Users</NavLink>
                                <NavLink to="/admin/stores" className="btn btn-text-primary border-0 p-0 me-4">Stores</NavLink>
                                <NavLink to="/admin/themes" className="btn btn-text-primary border-0 p-0 me-4">Themes</NavLink>
                                <NavLink to="/admin/offers" className="btn btn-text-primary border-0 p-0 me-4">Offers</NavLink>
                                <button className="btn btn-outline-danger btn-sm" onClick={logout}>Logout</button>
                            </div>
                        </div>
                    </div>
                    <div className="  ">
                        <div className="row d-flex justify-content-between">
                            <div className='col-md-4'>
                                <h4 className='mb-3'>Users ({totalCount})</h4>
                            </div>
                            <div className='col-md-4'>
                                <div className='search_main'>
                                    <div className='form-group mb-3'>
                                        <input placeholder='Search here...' type="search" className='form-control small' value={search} name="first_name" onChange={handleSearchChange} required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card p-0'>

                            {userData && userData.length > 0 ?
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Stores</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {userData.map((data, index) => {
                                            let updInd = index + 1
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {(params.page - 1) * 20 + updInd}
                                                    </td>
                                                    <td>{data.first_name} {data.last_name || ""}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.phone || "-"}</td>
                                                    <td>{data.storeCount}</td>
                                                    <td>{moment(data.created_at).format("lll")}</td>
                                                    <td style={{ width: 200 }}>
                                                        <NavLink to={`/admin/users/${data.id}`} className='btn btn-success btn-sm me-3 px-3'>View</NavLink>
                                                        <button className='btn btn-primary btn-sm px-3' onClick={() => openModal(data)}>Edit</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table> :
                                <div className='text-center py-5'>
                                    <h2>No data found</h2>
                                </div>
                            }
                        </div>

                        {/* {userData && userData.length === 0 ? null : */}
                            <Pagination>
                                <Pagination.First onClick={prevPage} disabled={params.page === 1} />
                                {getPageItems()}
                                <Pagination.Last
                                    onClick={nextPage}
                                    disabled={params.page === count.length}
                                // disabled={params.page === count}
                                />
                            </Pagination>
                        {/* } */}
                    </div>
                </div>
            </div >
            <Modal show={show} onHide={handleClose} size='md'>
                <Modal.Header closeButton>
                    <h5>Edit user detail</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <div className='login_outer'>
                            <div className='form-group mb-3'>
                                <div>
                                    <input placeholder='First name*' type="text" className='form-control' defaultValue={value?.first_name || modalData?.first_name} name="first_name" onChange={handleOnchange} />
                                    {errors && value?.first_name == "" ?
                                        <p className='mb-0 error'><small>Please enter first name</small></p>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className='form-group mb-3'>
                                <div>
                                    <input placeholder='Last name' type="text" className='form-control' defaultValue={value?.last_name || modalData?.last_name} name="last_name" onChange={handleOnchange} />
                                </div>
                            </div>
                            <div className='form-group mb-3'>
                                <div>
                                    <input placeholder='Email' type="email" className='form-control' defaultValue={value?.email || modalData?.email} name="email" onChange={handleOnchange} />
                                    {errors && value?.email == "" ?
                                        <p className='mb-0 error'><small>Please enter email</small></p>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className='form-group mb-3'>
                                <div className='customPhone'>
                                    <PhoneInput
                                        enableSearch={true}
                                        disableAreaCodes={false}
                                        country={'in'}
                                        value={value?.phone || modalData?.phone}
                                        // onChange={phone => setPhone({ phone })}
                                        inputExtrastate={{ name: 'mobile', required: true }}
                                        inputProps={{ maxLength: '17' }}
                                        onChange={(e, x) => handleNumberChange(e, x)}
                                        isValid={(value, data) => {
                                            if (
                                                value
                                                    .replace(/[^0-9]+/g, "")
                                                    .slice(data && (data.dialCode.length)).length < 10 &&
                                                value
                                                    .replace(/[^0-9]+/g, "")
                                                    .slice(data && (data.dialCode.length)).length > 0
                                            ) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        }}
                                        placeholder=""
                                        countryCodeEditable={false}
                                        defaultMask=".. ... ...."
                                    />
                                </div>
                            </div>
                            <hr />
                            <button className='btn btn-primary btn-sm me-2' onClick={handleSubmit}>Submit</button>
                            <button className='btn btn-outline-danger btn-sm' onClick={handleClose}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
