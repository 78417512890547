import React from 'react';
import _ from "underscore";
import moment from "moment";
import logo from "../../../Assets/Images/logo2.png";

export default function DownloadInvoice({ data }) {
    const style = {
        pdf: {
            height: "100%",
            width: "575px",
            backgroundColor: "#fff",
            color: "#000",
            margin: "10px",
            padding: "20px",
            fontSize: "10px",
            fontFamily: "Arial"
        },
        header: {
            display: "table",
            color: "#000",
            width: "100%"
        },
        header_row: {
            color: "#000",
            display: "table-row",
        },
        header_left: {
            color: "#000",
            display: "table-cell",
            whiteSpace: "nowrap",
            width: "50%"
        },
        header_right: {
            color: "#000",
            display: "table-cell",
            whiteSpace: "nowrap",
            width: "50%"
        },
        table: {
            color: "#000",
            borderCollapse: "collapse",
            width: "100%",
            border: 0,
        },
        tableTH: {
            color: "#000",
            padding: "5px 15px",
            fontSize: "10px",
            letterSpacing: "1px",
            border: "0",
            borderBottom: "#eee 1px solid"
        },
        tableTH2: {
            color: "#000",
            padding: "5px 0",
            fontSize: "11px",
            letterSpacing: "0",
            border: "0",
            borderBottom: "#eee 1px solid",
            width: "33.33%"
        },
        address: {
            color: "#000",
            margin: "30px 0",
            display: "table-cell",
        },
        m0: {
            margin: 0
        },
        hr: {
            color: "#000",
            borderTop: "#eee 1px solid",
            margin: "20px 0"
        },
        hr2: {
            color: "#000",
            borderTop: "#eee 1px solid",
            margin: "5px 0"
        }
    }
    console.log("pdf data: ", data)
    return (
        <div className="pdfBloch_inner" style={style.pdf}>
            <div className="pdf_mainBlock">
                <div style={style.header}>
                    <div style={style.header_row}>
                        <div style={style.header_left}>
                            <div>
                                <div style={{ marginBottom: "10px" }}>
                                    <img src={logo} alt="" width="100" />
                                </div>
                                <p style={style.m0}>#918, 9th floor, Tower - B</p>
                                <p style={style.m0}>Bestech Business Towers</p>
                                <p style={{marginBottom: "5px"}}>Sector 66, Mohali, Punjab</p>
                                <p style={style.m0}>Company: Web Experts Online</p>
                                <p style={style.m0}>GSTIN 03BVKPS1590K1Z7</p>
                            </div>
                        </div>
                        <div style={style.header_right}>
                            {data?.id ?
                                <p style={style.m0}><span>Invoice ID: </span><span style={{ color: "#f1592a" }}>{data?.id}</span></p> : null
                            }
                            {data?.payment_id ?
                                <p style={{ marginBottom: "5px" }}><span>Payment ID: </span><span>{data?.payment_id}</span></p> : null
                            }
                            {data?.paid_at ?
                                <p style={style.m0}><span>Paid on: </span>{moment(data?.paid_at * 1000).format("lll")}</p> : null
                            }
                            <p style={style.m0}><span>Billing cycle: </span>30 Days</p>
                            {data?.billing_start && data?.billing_end ?
                                <p style={style.m0}>{moment(data?.billing_start * 1000).format("ll")} - {moment(data?.billing_end * 1000).format("ll")}</p> : null
                            }
                        </div>
                    </div>
                </div>
                <div style={style.hr}></div>
                <div style={style.header}>
                    <div style={style.header_row}>
                        <div style={style.header_left}>
                            {data?.amount_paid ?
                                <>
                                    <p style={{ marginBottom: "5px" }}><span >Amount paid: </span></p>
                                    <p style={{ marginBottom: "0", fontSize: "12px" }}><span >{data?.currency || "INR"} {(data?.amount_paid / 100).toFixed(2)}</span></p>
                                </>
                                : null
                            }
                        </div>
                        <div style={style.header_right}>
                            <p style={{ marginBottom: "5px" }}><span>Issued to</span></p>
                            {data?.customer_details?.customer_name ?
                                <p style={{ marginBottom: "0" }}>{data?.customer_details?.customer_name}</p>
                                : null
                            }
                            {data?.customer_details?.email ?
                                <p style={{ marginBottom: "0" }}>{data?.customer_details?.email}</p>
                                : null
                            }
                            {data?.customer_details?.contact ?
                                <p style={{ marginBottom: "0" }}>{data?.customer_details?.contact}</p>
                                : null
                            }
                            {data?.address && !_.isEmpty(data?.address) ?
                                <p style={{ marginBottom: "0" }}>{data?.address?.address || ""}{data?.address?.apartment ? "," : ""} {data?.address?.apartment || ""}{data?.address?.city ? "," : ""} {data?.address?.city || ""}{data?.address?.state ? "," : ""} {data?.address?.state || ""}{data?.address?.country ? "," : ""} {data?.address?.country || "India"}{data?.address?.pincode ? "," : ""} {data?.address?.pincode || ""}</p>
                                : null

                            }
                        </div>
                    </div>
                </div>

                <div style={{ margin: "20px 0", whiteSpace: 'nowrap', width: "100%" }}>
                    {data?.line_items && data.line_items.length > 0 &&
                        <table style={style.table} cellSpacing="0" cellPadding="0">
                            <tbody>
                                {data.line_items.map((opt, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={style.tableTH2}><span style={{ color: "#f1592a" }}>{opt?.name}</span></td>
                                            <td style={style.tableTH2}>{data?.currency || "INR"} {(opt?.unit_amount / 100).toFixed(2)} X <span>{opt?.quantity}</span></td>
                                            <th style={style.tableTH2}>
                                                <div style={{ textAlign: "right" }}>{data?.currency || "INR"} {(opt?.amount / 100).toFixed(2)}</div>
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    }
                </div>
                <div style={style.header}>
                    <div style={style.header_row}>
                        <div style={style.header_left}>

                        </div>
                        <div style={style.header_right}>
                            <p style={{ marginBottom: "0" }}>
                                <span style={{ width: "50%", display: "inline-block" }}>Subtotal: </span>
                                <span style={{ width: "50%", display: "inline-block", textAlign: "right" }}>{data?.currency || "INR"} {(data?.subtotal || 0).toFixed(2)}</span>
                            </p>
                            <p style={{ marginBottom: "0" }}>
                                <span style={{ width: "50%", display: "inline-block" }}>IGST (18.0%): </span>
                                <span style={{ width: "50%", display: "inline-block", textAlign: "right" }}>{data?.currency || "INR"} {((data?.subtotal || 0)*18/100).toFixed(2)}</span>
                            </p>
                            <p style={{ marginBottom: "0" }}>
                                <span style={{ width: "50%", display: "inline-block" }}>Round off: </span>
                                <span style={{ width: "50%", display: "inline-block", textAlign: "right" }}>{data?.currency || "INR"} {(0.18).toFixed(2)}</span>
                            </p>
                            <div style={style.hr2}></div>
                            <p style={{ marginBottom: "0" }}>
                                <span style={{ width: "50%", display: "inline-block" }}>Total: </span>
                                <span style={{ width: "50%", display: "inline-block", textAlign: "right", fontSize: "12px" }}>{data?.currency || "INR"} {((data?.amount || 0) / 100).toFixed(2)}</span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}