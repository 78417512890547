import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as Api from "../../Apis";
import Images from '../common/Index';
import { useMediaQuery } from "@uidotdev/usehooks";

function Login() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    let navigate = useNavigate()
    const [value, setValue] = useState({})
    const [btnLoader, setbtnLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [show, setShow] = useState(false)
    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        if (e.target.name == "password") {
            if (e.target.value !== "") {
                setShowPassword(true)
            } else {
                setShowPassword(false)
            }
        }
    }
    const changeVisibility = () => {
        setShow(!show)
    }


    const getStore = () => {

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {}
        if (value?.email.trim() == "") {
            Api.Alert("Please enter your email", "error")
        } else if (value?.password.trim() == "") {
            Api.Alert("Please enter your password", "error")
        } else {
            setbtnLoader(true)
            if (localStorage.getItem("login_token") && localStorage.getItem("login_token") !== "") {
                data = {
                    ...value,
                    login_token: localStorage.getItem("login_token")
                }
            } else {
                data = {
                    ...value
                }
            }
            Api.PostApi("users/login", data).then(res => {
                console.log("res login: ", res)
                setbtnLoader(false)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data.token) {
                        // if (res.data.data.is_verified == 0) {
                        //     Api.Alert("Please verify your email to login.", "error")
                        // } else {
                        Api.setToken("token", res.data.data.token)
                        let auth_data = {}
                        auth_data["email"] = res.data.data?.email
                        auth_data["first_name"] = res.data.data?.first_name
                        auth_data["last_name"] = res.data.data?.last_name
                        auth_data["phone"] = res.data.data?.phone
                        localStorage["auth_data"] = JSON.stringify(auth_data)
                        localStorage.removeItem("login_token")
                        Api.GetApi('store?page=1&type=active').then(res => {
                            console.log("res list: ", res)
                            if (res.error === true) {
                                Api.Alert(res.response.data.error, "error")
                                if (res.res.data?.errorCode == "TOKEN_EXPIRE") {
                                    Api.removeToken()
                                }
                            } else {
                                if (res.data.data.length == 0) {
                                    Api.GetApi('store?page=1&type=expire').then(res2 => {
                                        console.log("res2 list: ", res2)
                                        if (res2.error === true) {
                                            Api.Alert(res2.response.data.error, "error")
                                            if (res2.res.data?.errorCode == "TOKEN_EXPIRE") {
                                                Api.removeToken()
                                            }
                                        } else {
                                            if (res.data.data.length == 0) {
                                                navigate("/store-login")
                                            }
                                        }
                                    })
                                }
                                if (res.data.data.length == 1) {
                                    let resData = res.data.data[0]
                                    Api.PostApi("store/storelogin", { "store_id": resData?.storeId }).then(res => {
                                        console.log("res store login: ", res)
                                        if (res.error === true) {
                                            Api.Alert(res.response.data.error, "error")
                                        } else {
                                            if (res.data.data.token) {
                                                let url = ""
                                                let domain = ""
                                                if (resData?.liveDomain) {
                                                    domain = resData?.liveDomain
                                                } else {
                                                    domain = `https://${resData?.domain}`
                                                }

                                                if (Api.buildtype == "local") {
                                                    url = `http://${resData.store_name.split(" ").join("-")}.localhost:3001/admin?token=${res.data.data.token}&id=${resData?.store[0]?.id}&role=${resData?.store[0]?.role}`
                                                } else {
                                                    url = `https://${resData?.domain}/admin?token=${res.data.data.token}&id=${resData?.store[0]?.id}&role=${resData?.store[0]?.role}`
                                                }
                                                if (resData?.store[0]?.role == 3 && !resData?.store[0]?.is_accepted) {
                                                    // let a = document.createElement('a');
                                                    // a.href = url;
                                                    // if (window.confirm('Accept account invite. Click Ok')) {
                                                    //     Api.PostApi('staff/accept', { store_id: resData.storeId }).then(resp => {
                                                    //         console.log("res accept list: ", resp)
                                                    //         if (resp.error === true) {
                                                    //             Api.Alert(resp.response.data.error, "error")
                                                    //             if (resp.res.data.errorCode == "TOKEN_EXPIRE") {
                                                    //                 Api.removeToken()
                                                    //             }
                                                    //         } else {
                                                    //             a.click();
                                                    //         }
                                                    //     })
                                                    // };
                                                    navigate("/store-login")
                                                } else {
                                                    navigate("/store-login")
                                                    setTimeout(() => {
                                                        window.open(url, '_self')
                                                    }, 100);
                                                }
                                            }
                                        }
                                    })
                                } else {
                                    navigate("/store-login")
                                }
                            }
                        })
                        // }
                    }
                }
            })
        }
    }
    // console.log("value: ", value)
    useEffect(() => {
    })

    return (
        <div className='loginContainer'>
            <div className="cardBox cardBox__login" >
                <div className='loginLogo'>
                    <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                </div>
                <div className='cardBoxStore__bodyHeading'>
                    <h4>Log in with Shopyxa </h4>
                </div>

                <form onSubmit={handleSubmit} className='login_outer' >
                    <div className='form-group mb-3'>
                        <div>
                            <input placeholder='Email' type="email" className='form-control' name="email" onChange={handleOnchange} required />
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='flex_input'>
                            <input placeholder='Password' type={show ? "text" : "password"} className='form-control' name="password" onChange={handleOnchange} required />
                            {/* {showPassword && */}
                            <span className="form-addon-icon" onClick={changeVisibility}>
                                {!show ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                            {/* } */}
                        </div>
                    </div>
                    <Link to={value?.email && value?.email !== "" ? `/forgot-password?email=${value.email}` : "/forgot-password"} className='loginHeading__forgotpassword'>Forgot Password?</Link>

                    <div className='form-group mb-3'>
                        <button className={`btn btn-primary w-100 ${btnLoader ? "disabled" : ""}`}>Log in</button>
                    </div>
                    <hr />
                    <div className='signUpLink'>
                        <p>Don’t have an account? </p>
                        <NavLink to="/signup" className='btn btn-secondary w-100'>Get started</NavLink>
                    </div>
                    {/* <div className='orLoginWith'><span>or login with</span></div>
                    <div className="loginWithOption">
                        <button className='loginWithOption__btn'><img src={Images.google} alt="" /> <span>Google </span></button>
                        <button className='loginWithOption__btn'><img src={Images.facebook} alt="" /> <span>Facebook </span></button>
                    </div> */}
                </form>
            </div>
        </div>
    );
}

export default Login;