import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import * as Api from "../../../Apis";
import Images from '../../common/Index';
import { Helmet } from "react-helmet";
import { useMediaQuery } from '@uidotdev/usehooks';

function SupportConnect() {
    let navigate = useNavigate()
    const [value, setValue] = useState({})
    const [btnLoader, setbtnLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [show, setShow] = useState(false)
    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        if (e.target.name == "password") {
            if (e.target.value !== "") {
                setShowPassword(true)
            } else {
                setShowPassword(false)
            }
        }
    }
    const changeVisibility = () => {
        setShow(!show)
    }
    // useEffect(()=>{        
    //     localStorage.clear()
    // },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {}
        if (value?.pin == "") {
            Api.Alert("Please enter pin", "error")
        } else if (value?.email == "") {
            Api.Alert("Please enter customer email", "error")
        } else {
            setbtnLoader(true)
            data = {
                ...value
            }
            Api.PostApiSupport("support/store/login", data).then(res => {
                console.log("res login: ", res)
                setbtnLoader(false)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data) {
                        let resData = res.data.data
                        // Api.setToken("token", resData?.token)
                        let url = ""
                        let domain = ""
                        if(resData?.liveDomain){
                            domain = resData?.liveDomain
                        }else{
                            domain = `https://${resData?.domain}`
                        }
                        if (Api.buildtype == "local") {
                            url = `http://${resData?.store_name.split(" ").join("-")}.localhost:3001/admin?token=${resData?.token}&id=${resData?.store_id}&role=${resData?.role}`
                        } else {
                            url = `https://${resData?.domain}/admin?token=${resData?.token}&id=${resData?.store_id}&role=${resData?.role}`
                        }
                        window.open(url, '_self')
                    }
                }
            })
            // }
        }
    }
    console.log("value: ", value)
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    return (
        <div className='loginContainer'>
            <Helmet>
                <title>Shopyxa | Support</title>
            </Helmet>
            <div className="cardBox cardBox__login" >
                <div className='loginLogo'>
                <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                </div>
                <div className='cardBoxStore__bodyHeading'>
                    <h4>Enter details to access store</h4>
                </div>

                <form onSubmit={handleSubmit} className='login_outer' >
                    <div className='form-group mb-3'>
                        <div>
                            <input placeholder='Customer email' type="email" className='form-control' value={value?.email} name="email" onChange={handleOnchange} required />
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div>
                            <input placeholder='Pin' type="text" className='form-control' value={value?.pin} name="pin" onChange={handleOnchange} required />
                        </div>
                    </div>


                    <div className='form-group mb-3'>
                        <button className={`btn btn-primary w-100 ${btnLoader ? "disabled" : ""}`}>Connect</button>
                    </div>
                </form>
                <hr />
                <div className='signUpLink text-end'>
                    <NavLink to="/support">Back to dashboard</NavLink>
                </div>
            </div>
        </div>
    );
}

export default SupportConnect;