import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Api from "../../Apis";
import Images from '../common/Index';
import LinearProgress from '../common/LinearProgress';
import { useMediaQuery } from "@uidotdev/usehooks";

function Verify() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    let navigate = useNavigate()
    let location = useLocation()
    const [message, setMessage] = useState("Wait patiently while we verifying your account...")
    console.log("location: ", location)

    const verifyToken = (token) => {
        Api.PostApi("users/verify", { token: token }).then(res => {
            console.log("res verify: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setMessage(res.response.data.error)
            } else {
                if (res.data.data) {
                    // Api.setToken("token", token)
                    setTimeout(() => {
                        // alert(res.data.data)
                        if (!window.location.pathname.includes("/store")) {
                            Api.Alert("User verified successfully", "success")
                        }
                        if (Api.isLogIn().isToken && Api.isLogIn().token) {
                            // localStorage.removeItem("token")
                            // localStorage.removeItem("auth_data")
                            //// localStorage.clear()
                            localStorage["login_token"] = res.data?.testkey
                            if (res.data.data?.role && res.data.data?.role == 1) {
                                localStorage["token"] = token
                                let auth_data = {}
                                auth_data["email"] = res.data.data?.email
                                auth_data["first_name"] = res.data.data?.first_name
                                auth_data["last_name"] = res.data.data?.last_name
                                auth_data["phone"] = res.data.data?.phone
                                localStorage["auth_data"] = JSON.stringify(auth_data)
                            }
                        }
                        setTimeout(() => {
                            navigate("/login")
                        }, 1000);

                    }, 2000);
                }
            }
        })
    }
    useEffect(() => {
        let token = location.search.split("?token=")[1]
        if (token) {
            verifyToken(token)
        }
    }, [])
    return (
        <div className="verifyPage d-flex align-items-center justify-content-center">
            <div className="verifyPage__container position-relative text-center">
                <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="Loading" width="200" />
                <div className='loader_outer'>
                    <LinearProgress />
                </div>
                {/* <img src={Images.dreamer} className="img-fluid d-block" alt="" /> */}
                <div className="verifyPage__content text-center">
                    <h4 className="mb-0">{message}</h4>
                </div>
            </div>
        </div>
    );
}

export default Verify;