import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import * as Api from "../../Apis";
import Images from '../common/Index';
import { useMediaQuery } from "@uidotdev/usehooks";

function ForgotPassword() {
    const searchParams = new URLSearchParams(typeof window !== 'undefined' && window.location.search)
    let navigate = useNavigate()
    const [value, setValue] = useState({
        email: searchParams.get("email") || ""
    })
    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (value?.email.trim() == "") {
            Api.Alert("Please enter your email", "error")
        } else {
            let data = {
                ...value
            }
            Api.PostApi("users/forgot-password", data).then(res => {
                console.log("res login: ", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    // if (process.env.REACT_APP_MODE == "dev" && res.data.data?.testkey) {
                    //     let a = document.createElement('a');
                    //     a.href = res.data.data?.testkey;
                    //     a.setAttribute('target', '_blank')
                    //     if (window.confirm('Accept account invite. Click Ok')) {
                    //         a.click();
                    //     }
                    // } else {
                    //// Api.Alert(res.data.data?.message, "success")
                    Api.Alert(res.data.data, "success")
                    // }
                }
            })
        }
    }
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    console.log("value: ", value)
    return (
        <div className='loginContainer'>
            <div className="cardBox cardBox__login" >
                <div className='loginLogo'>
                    <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                </div>
                <div className='cardBoxStore__bodyHeading'>
                    <h4>Forgot your password </h4>
                    <p className="mb-0">Please enter your email address. You will receive a link to create a new password via email</p>
                </div>
                <form className='login_outer' onSubmit={handleSubmit}>
                    <div className='form-group mb-3'>
                        <div>
                            <input placeholder='Email' type="email" className='form-control' name="email" value={value.email || ""} onChange={handleOnchange} required />
                        </div>
                    </div>

                    <div className='form-group'>
                        <button className='btn btn-primary w-100'> Request password reset</button>
                    </div>

                </form>
            </div>
            <div className='signUpLink text-center'>
                Back to {" "}<NavLink to="/login"> <strong>Log in</strong></NavLink>
            </div>
        </div>
    );
}

export default ForgotPassword;