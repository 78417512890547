import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_MODE !== 'prod') {
    const meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex, nofollow";
    document.getElementsByTagName('head')[0].appendChild(meta);
}
if (process.env.REACT_APP_MODE == 'dev') {
  const meta = document.createElement('meta');
  meta.name = "google-site-verification";
  meta.content = "2MdeM2_deSFwQosElXmnIDcYH05tGBObmkIZoPoaokY";
  document.getElementsByTagName('head')[0].appendChild(meta);
}
if (process.env.REACT_APP_MODE == 'prod') {
  const meta = document.createElement('meta');
  meta.name = "google-site-verification";
  meta.content = "kXBkaZcVzkkams2ANTA8dQqjQxzmOByml9N6KGryrrg";
  document.getElementsByTagName('head')[0].appendChild(meta);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
