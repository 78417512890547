const Images = {
    logo: require('../../Assets/Images/logo.svg').default,
    logo_white: require('../../Assets/Images/logo-wt.svg').default,
    logo_animated: require('../../Assets/Images/logo_animated.gif'),
    facebook: require('../../Assets/Images/facebook.svg').default,
    google: require('../../Assets/Images/google.svg').default,
    dreamer: require('../../Assets/Images/dreamer.svg').default,
    PageNotFound: require('../../Assets/Images/404.svg').default,
    loader: require('../../Assets/Images/loader.svg').default,
}
export default Images;