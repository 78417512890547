import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { isLogIn, removeToken } from '../Apis';

function Home() {
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(typeof window !== 'undefined' && window.location.search)
    useEffect(() => {
        if (window.location.pathname == "/logout") {
            if(searchParams.get("type") && searchParams.get("type") == "support"){
                navigate("/support/login")
            }else{
                localStorage.clear()
                navigate("/login")                
            }
        } else {
            if (isLogIn().isToken) {
                navigate("/store-login")
            } else {
                navigate("/login")
            }
        }
    }, [])
    return (
        <div>
        </div>
    );
}

export default Home;