import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as Api from "../../Apis";
import Images from '../common/Index';
import { useMediaQuery } from "@uidotdev/usehooks";

function ResetPassword() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    let navigate = useNavigate()
    let location = useLocation()
    const [value, setValue] = useState({
        password: "",
        confirm_password: ""
    })
    const [btnLoader, setBtnLoader] = useState(false)
    
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [strength, setstrength] = useState("")

    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        if (e.target.name == "password") {
            var reg = new RegExp(
                "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
            );
            var validPass = reg.test(e.target.value);

            if (e.target.value.length >= 8 && !validPass) {
                setstrength("Medium")
            } else if (e.target.value.length >= 8 && validPass) {
                setstrength("Strong")
            } else if (e.target.value == "") {
                setstrength("")
            } else {
                setstrength("Weak")
            }
        }
    }
    const changeVisibility = (type) => {
        if (type == "password") {
            setShow(!show)
        } else {
            setShow2(!show2)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var reg = new RegExp(
            "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
        );
        var validPass = reg.test(value?.password);

        if (value?.password.trim() == "") {
            Api.Alert("Password can't be blank", "error")
        } else if (value?.confirm_password.trim() == "") {
            Api.Alert("Please confirm password", "error")
        } else if (value?.confirm_password !== value?.password) {
            Api.Alert("Password confirmation does not match.", "error")
        } else if (value?.password.trim() !== "" && value?.password.length < 4) {
            Api.Alert("Pick a stronger password for your account. Try using a combination of letters, numbers, and special symbols.", "error")
        } else {
            if (location.search.split("?token=")[1]) {
                setBtnLoader(true)
                let data = {
                    ...value,
                    token: location.search.split("?token=")[1]
                }
                console.log()
                Api.PostApi("users/change-password", data).then(res => {
                    console.log("res change-password: ", res)
                    setBtnLoader(false)
                    if (res.error === true) {
                        Api.Alert(res.response.data.error, "error")
                    } else {
                        Api.Alert(res.data.data, "success")
                        setTimeout(() => {
                            navigate("/login")
                        }, 1500);
                    }
                })
            } else {
                Api.Alert("Something went wrong", "error")
            }
        }
    }
    console.log("value: ", value)
    return (
        <div className='loginContainer'>
            <div className="cardBox cardBox__login" >
                <div className='loginLogo'>
<img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                </div>
                <div className='cardBoxStore__bodyHeading'>
                    <h4>Set new password </h4>
                    <p className="mb-0">Please enter a new password.</p>
                </div>
                <form className='login_outer' onSubmit={handleSubmit}>
                    <div className='form-group mb-3'>
                        <div className='flex_input'>
                            <input type={show ? "text" : "password"} name="password" value={value?.password} className={`form-control w-100 required ${value?.password == "" ? "" : "filled"}`} placeholder='Password*' onChange={handleOnchange} />

                            {value?.password !== "" &&
                                <div className='password_bar'>
                                    <span style={{
                                        width: `${value?.password.length * 10 / 2}%` || "0%",
                                        backgroundColor: strength == "Strong" ? "#54d55a " : strength == "Medium" ? "#979797" : "#ff0000"
                                    }}></span>
                                </div>
                            }

                            <span className="form-addon-icon" onClick={() => changeVisibility("password")}>
                                {!show ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                        </div>
                        <p className="mt-1" style={{ color: strength == "Strong" ? '#54d55a' : strength == "Medium" ? "#979797" : '#ff0000' }}><strong>{strength}</strong></p>
                    </div>
                    <div className='form-group mb-3'>
                        {/* <div>
                            <input placeholder='Confirm Password' type="password" className='form-control' name="confirm_password" onChange={handleOnchange} />
                        </div> */}
                        <div className='flex_input'>
                            <input type={show2 ? "text" : "password"} name="confirm_password" value={value?.confirm_password} className={`form-control w-100 required ${value?.confirm_password == "" ? "" : "filled"}`} placeholder='Confirm Password*' onChange={handleOnchange} />
                            <span className="form-addon-icon" onClick={() => changeVisibility("confirm_password")}>
                                {!show2 ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                        </div>
                    </div>
                    {value?.password !== "" &&
                        <ol className='password_note'>
                            <li>The Password must contain atleast 8 Characters</li>
                            <li>The Password must have atleast one Capital Letter</li>
                            <li>The Password must have atleast one Number and Special Character</li>
                        </ol>
                    }

                    <div className='form-group mb-3'>
                        <button className={`btn btn-primary w-100 ${btnLoader ? "disabled" : ""}`}>{btnLoader ? "Please wait" : "Reset password"}</button>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default ResetPassword;