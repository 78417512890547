import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';

import * as Api from "../../Apis"
import './Network.css'
import Images from '../common/Index';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import { useMediaQuery } from '@uidotdev/usehooks';


export default function Network() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const searchParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()
    const [activeTab, setactiveTab] = useState("pending")
    const [listingData, setlistingData] = useState([])
    const [modalData, setmodalData] = useState({})
    const [tab, settab] = useState([
        { name: "Pending", value: "pending", active: true },
        { name: "Completed", value: "completed", active: false },
    ])
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
    })
    let limit = 20
    const [count, setCount] = useState(1)

    const [show, setshow] = useState(false)
    const handleClose = () => {
        setmodalData({})
        setshow(false)
    }
    const changeTab = (val) => {
        if (activeTab === "pending") {
            setactiveTab("completed")
        } else {
            setactiveTab("pending")
        }

    }

    const logout = () => {
        localStorage.removeItem("network_token")
        navigate("/network/login")
    }
    const getList = (status) => {
        // ?search=complete
        Api.GetApiNetwork(`domain-verification?search=${status}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {

                setlistingData(res.data.data.rows)
                handleClose()
                let newCount = Math.ceil(parseInt(res.data.data?.count) / limit)

                if (newCount !== 0) {
                    let items = [];
                    for (let number = 1; number <= newCount; number++) {
                        items.push(number);
                    }
                    setCount(items)
                } else {
                    setCount(1)
                }

            }
        })
    }

    console.log("res", listingData)

    const updateStatus = () => {
        Api.PutApiNetwork(`domain-verification/updateStatus/${modalData.domain_verification_id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                Api.Alert(res.data.data.message, "success")
                getList(activeTab)
                changeTab()
            }
        })

    }

    const openModal = (data) => {
        setshow(true)
        setmodalData(data)
    }

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const maxVisiblePages = 5;

    const getPageItems = () => {
        const pageItems = [];
        const totalPages = count.length;

        for (let number = 1; number <= totalPages; number++) {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= params.page - Math.floor(maxVisiblePages / 2) &&
                    number <= params.page + Math.floor(maxVisiblePages / 2))
            ) {
                pageItems.push(
                    <Pagination.Item
                        key={number}
                        active={number === params.page}
                        onClick={() => pageChange(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            } else if (
                (number === 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
                (number === totalPages - 1 &&
                    params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
            ) {
                // Display ellipsis for the second and second-to-last pages
                pageItems.push(<Pagination.Ellipsis key={number} />);
            }
        }

        return pageItems;
    };

    const prevPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) - 1)
        setParams({ ...params, page: Number(params.page) - 1 })
    }
    const nextPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) + 1)
        setParams({ ...params, page: Number(params.page) + 1 })
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            // setloading(true)
        }
    }
    useEffect(() => {
        getList(activeTab)
    }, [activeTab])
    return (
        <>

            <div className='loginContainer justify-content-start py-3 networkPage'>
                <div className="container">
                    <div className="metworkHeader ">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="loginLogo">
                                <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                            </div>
                            <button className="btn btn-text-danger border-0 p-0" onClick={logout}>Logout</button></div>
                    </div>
                    <div className="  ">
                        <ul className='mb-0 nav nav-tabs'>
                            {tab.map((data, index) => {
                                return (
                                    <li key={index} className="nav-item"><button className={activeTab === data.value ? `nav-link active` : "nav-link"} onClick={() => changeTab(data.value)}>{data.name}</button></li>
                                )
                            })}
                        </ul>
                        <div className='card p-0'>

                            {listingData && listingData.length > 0 ?
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User </th>
                                            <th>Shopyxa domain </th>
                                            <th>Primary domain </th>
                                            <th>Status</th>
                                            <th>Current step</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {listingData.map((data, index) => {
                                            let updInd = index + 1
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {/* {index + 1} */}
                                                        {(params.page - 1) * 20 + updInd}
                                                    </td>
                                                    {data?.storeDetails && data?.storeDetails?.user ?
                                                        <td>
                                                            <p className='mb-0'>{data?.storeDetails?.user?.first_name || ""} {data?.storeDetails?.user?.last_name || ""}</p>
                                                            <p className='mb-0' style={{ color: "#888" }}>{data?.storeDetails?.user?.email || ""}</p>
                                                        </td>
                                                        :
                                                        <td>
                                                            NA
                                                        </td>
                                                    }
                                                    <td>{data?.primaryDomain} </td>
                                                    <td>{data?.liveDomain} </td>
                                                    <td>{data?.status} </td>
                                                    <td>{data?.currentStep} </td>
                                                    <td>
                                                        {activeTab === "completed" ?
                                                            <strong>Completed</strong> :
                                                            <button className='btn btn-success btn-sm' disabled={data?.currentStep === 3 ? false : true}
                                                                onClick={() => openModal(data)}
                                                            >Approve</button>
                                                        }

                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table> :
                                <div className='text-center py-5'>
                                    <h2>No data found</h2>
                                </div>
                            }
                        </div>

                        {/* {listingData && listingData.length === 0 ? null : */}
                        <Pagination>
                            <Pagination.First onClick={prevPage} disabled={params.page === 1} />
                            {getPageItems()}
                            <Pagination.Last
                                onClick={nextPage}
                                disabled={params.page === count.length}
                            // disabled={params.page === count}
                            />
                        </Pagination>
                        {/* } */}
                    </div>
                </div>
            </div >

            <Modal show={show} onHide={handleClose} size='md'>

                <Modal.Body>
                    <div className="text-center mb-4">
                        <h4> Are you sure?</h4>
                        <h5>You want to approve the status.</h5>
                    </div>
                    <div className="text-center mt-2">
                        <button className="btn btn-success btn-sm me-3" onClick={updateStatus} > Confirm</button>
                        <button className="btn btn-outline-danger btn-sm" onClick={handleClose}> Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
