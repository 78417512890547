import React from 'react';

function LinearProgress() {
    return (
        <div className='linear_progress'>
            <div className='linear_progress_bar'></div>
        </div>
    );
}

export default LinearProgress;