import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import * as Api from "../../../Apis";
import Images from '../../common/Index';
import { Helmet } from "react-helmet";
import { useMediaQuery } from '@uidotdev/usehooks';

function SupportLogin() {
    let navigate = useNavigate()
    const [value, setValue] = useState({})
    const [btnLoader, setbtnLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [show, setShow] = useState(false)
    const handleOnchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        if (e.target.name == "password") {
            if (e.target.value !== "") {
                setShowPassword(true)
            } else {
                setShowPassword(false)
            }
        }
    }
    const changeVisibility = () => {
        setShow(!show)
    }
    // useEffect(()=>{        
    //     localStorage.clear()
    // },[])

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {}
        if (value?.email == "") {
            Api.Alert("Please enter your email", "error")
        } else if (value?.password == "") {
            Api.Alert("Please enter your password", "error")
        } else {
            setbtnLoader(true)
            if (localStorage.getItem("login_token") && localStorage.getItem("login_token") !== "") {
                data = {
                    ...value,
                    login_token: localStorage.getItem("login_token")
                }
            } else {
                data = {
                    ...value
                }
            }
            Api.PostApi("support/login", data).then(res => {
                console.log("res login: ", res)
                setbtnLoader(false)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    if (res.data.data?.token) {
                        localStorage.clear()
                        localStorage["support_token"] = res.data.data?.token
                        navigate("/support")
                    }
                }
            })
            // }
        }
    }
    console.log("value: ", value)
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    return (
        <div className='loginContainer'>
            <Helmet>
                <title>Shopyxa | Support</title>
            </Helmet>
            <div className="cardBox cardBox__login" >
                <div className='loginLogo'>
                    <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                </div>
                <div className='cardBoxStore__bodyHeading'>
                    <h4>Welcome to Shopyxa support</h4>
                </div>

                <form onSubmit={handleSubmit} className='login_outer' >
                    <div className='form-group mb-3'>
                        <div>
                            <input placeholder='Email' type="email" className='form-control' value={value?.email} name="email" onChange={handleOnchange} required />
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='flex_input'>
                            <input placeholder='Password' type={show ? "text" : "password"} className='form-control' value={value?.password} name="password" onChange={handleOnchange} required />
                            {/* {showPassword && */}
                            <span className="form-addon-icon" onClick={changeVisibility}>
                                {!show ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                            </span>
                            {/* } */}
                        </div>
                    </div>

                    <div className='form-group mb-3'>
                        <button className={`btn btn-primary w-100 ${btnLoader ? "disabled" : ""}`}>Log in</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SupportLogin;