import React from 'react'
import { Link } from 'react-router-dom'
import Images from './components/common/Index'

export default function PageNotFound() {
  return (
    <div className="verifyPage d-flex align-items-center justify-content-center">
            <div className="verifyPage__container text-center">
                <img src={Images.PageNotFound} className="img-fluid d-block" alt="" width={500} style={{margin: "auto"}} />
                <div className="verifyPage__content text-center">
                    <h2 className="mb-3">Page not found.</h2>
                    <p className='font-20'>There's no page at this address.</p>
                    <Link to="/" className='btn btn-primary'>Go To Homepage</Link>
                </div>
            </div>
        </div>
  )
}
